import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/component.layout';
import SEO from '../components/component.seo';
import ComponentParser from '../components/component.parser';
import HeroBanner from '../components/hero-banner/component.banner';
import Content from '../components/component.content';
import CookieNotice from "../components/component.cookie-notice";

const PageTemplate = ({ data }) => {
    const { title, content, blocks, featuredImage, seo, ACFFeaturedVideoBlockFields: video, language, translations, slug } = data.wordpress.page;
    const bannerImage = featuredImage ? featuredImage.node.imageFile : false;

    return (
        <Layout language={language} translations={translations}>
            <SEO title={title} seo={seo} image={featuredImage} translations={translations} slug={slug} language={language} />
            <HeroBanner title={title} image={bannerImage} video={video} />

            {content &&
                <Content content={content} />
            }
            {blocks &&
                <ComponentParser blocks={blocks} />
            }
            <CookieNotice />
        </Layout>
    );
};

export const query = graphql`
    query($id: ID!) {
        wordpress {
            page(id: $id) {
                title
                slug
                content
                language {
                    code
                    locale
                }
                # Get links to the translated versions of each page
                # This is an array of post objects
                translations {
                    uri
                    language {
                        code
                        locale
                    }
                }
                featuredImage {
                    node {
                        altText
                        sourceUrl
                        imageFile {
                            childImageSharp {
                                fluid(maxWidth: 1920) {
                                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                }
                            }
                        }
                    }
                }
                ACFFeaturedVideoBlockFields {
                    videoMp4 {
                        mediaItemUrl
                    }
                    videoWebm {
                        mediaItemUrl
                    }
                }
                seo {
                    ...SeoPostFields
                }
                ...PageBlocks
            }
        }
    }
`;

export default PageTemplate;
